/**
 * @file Auth context holds a client session id if the user is logged in
 *  Having a session means the client believes it is logged in. Api calls may still fail at server side if the
 *
 *
 * @author Kilian Brachtendorf <kilian@rachtendorf.dev>
 *
 */

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { readCookie } from '../utils';
import jwt_decode from 'jwt-decode';

const AuthContext = React.createContext({
  claims: null,
  username: readCookie('username'),
  logout: () => { },
  login: () => { },
});

AuthContext.displayName = 'AuthContext';

export default AuthContext;

export const AuthWrapper = ({ children }) => {
  const [authState, setAuthState] = React.useState({
    //Token is saved as a cookie but as secure value. So we might not be able to retrieve it.
    claims: (() => {
      try {
        const claims = localStorage.getItem('claims');
        return claims != null ? JSON.parse(claims) : null;
      } catch (err) {
        return null;
      }
    })(),
    //Username is saved in local storage
    username: localStorage.getItem('username'),
  });

  //const history = useHistory();
  const login = useCallback(
    //TODO history has to be provided until react admin is replaced. as auth context needs to be wrapping the router to use history
    (token, history) => {
      const { user, iat, ...claims } = jwt_decode(token);

      localStorage.setItem('username', user);
      if (claims) {
        localStorage.setItem('claims', JSON.stringify(claims));
      }

      setAuthState({
        claims: claims,
        username: user,
      });

      //Forward user to the overview page
      history.push('/');
    },
    []
  );

  const logout = useCallback((history) => {
    setAuthState({
      claims: null,
      username: null,
    });

    localStorage.removeItem('username');
    localStorage.removeItem('claims');

    history.push('/login');
  }, []);

  return (
    <AuthContext.Provider value={{ claims: authState.claims, username: authState.username, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);

export const LoginRequired = ({ children, claim }) => {
  const authContext = useAuthContext();
  const history = useHistory();

  //If a claim is required check if
  if (claim) {
    if (!authContext.claims || !authContext.claims[claim]) {
      history.push('/');
      return null;
    }
  }

  //Not logged in
  if (authContext.username == null) {
    history.push('/login');
    return null;
  }
  return <>{children}</>;
};

export const ClaimRequired = ({ children, claim }) => {
  const authContext = useAuthContext();
  //If a claim is required check if
  if (claim) {
    if (authContext.claims && authContext.claims[claim]) {
      return <>{children}</>;
    } else {
      return null;
    }
  }
  return <>{children}</>;
};

export const IsPaybylinkAllowedUser = ({ children, claim }) => {
  const authContext = useAuthContext();
  //If user is in Special User List render children

  if (['stefts', 'kilts'].includes(authContext.username)) {
    return <>{children}</>;
  } else {
    return null;
  }
};